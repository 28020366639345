import type {
  StageBrandMediaReferences,
  StageMediaReference,
} from '@components/Stage/Stage.types'
import type { WithNextImageComponent } from '@knauf-group/ct-designs/components/core/ImageWrapper'
import { ScreenSM } from '@knauf-group/ct-designs/utils/hooks/mediaQuery'
import { useTheme } from '@mui/material/styles'

type StageBrandImageProps = WithNextImageComponent &
  StageBrandMediaReferences &
  StageMediaReference

const StageBrandImage = ({
  nextImageComponent: Image,
  imageLoader,
  image,
  bgImageSmall,
  bgImageLarge,
}: StageBrandImageProps) => {
  const theme = useTheme()
  const isRTL = theme.direction === 'rtl'
  const isScreenSm = ScreenSM(theme)
  return isScreenSm ? (
    <Image
      src={bgImageSmall.url}
      alt={bgImageSmall.title}
      width="0"
      height="0"
      sizes="100vw"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
      unoptimized
      priority
    />
  ) : (
    <>
      <Image
        src={image.url}
        alt={image.title}
        width="0"
        height="0"
        sizes="45vw"
        style={{
          position: 'absolute',
          top: '0',
          left: isRTL ? '0' : 'unset',
          right: !isRTL ? '0' : 'unset',
          width: '45%',
          height: '100%',
          objectFit: 'cover',
          backgroundColor: '#fff',
          paddingBottom: '56px',
        }}
        loader={imageLoader}
        priority
      />
      <Image
        src={bgImageLarge.url}
        alt={bgImageLarge.title}
        width="0"
        height="0"
        sizes="100vw"
        style={{
          position: 'absolute',
          top: '0',
          left: !isRTL ? '-30%' : 'unset',
          right: isRTL ? '-30%' : 'unset',
          width: '100%',
          height: '100%',
          paddingBottom: '1px',
        }}
        unoptimized
        priority
      />
    </>
  )
}

export default StageBrandImage
